<template>
    <div>
         <h1>The Entourage</h1>
        <div class="story-board-container">
            <div class="story-panel">
                <div class='entourage-panel'>
                    <h2>Groom</h2>
                    <div class="entourage-section">
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/15.png`" :name="`Richlee Sapa`" :title="` Right Hand Man`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/eug.png`"  :name="`Eugene Rivera`" :title="` Right Hand Man`"></entourage-item>
                    </div>
                    <div class="entourage-section">
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/3.png`" :name="`Kathleen Bascon`" :title="`Kathleen Bascon`"></entourage-item>
                    </div>
                    <div class="entourage-section">
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/24.png`" :name="`John John Dumaicos`" :title="` One Piece Boy`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/30.png`" :name="`Kin Patingo`" :title="` Tropang Potchie `"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/19.png`" :name="`Rhen Mark Callado`" :title="` Worker From Home`"></entourage-item>
                        <div class="break"></div>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/18.png`" :name="`Ankush Mittal`" :title="` J.C DB`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/16.png`" :name="`Suraj Pasayat`" :title="` Sunny Boy`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/17.png`" :name="`Pankaj Parkar`" :title="` NG Boy`"></entourage-item>
                        <div class="break"></div>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/31.png`" :name="`Kemp Ramos`" :title="` Dual Citizen (Filipino/Senior)`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/25.png`" :name="`Reggie Cabalo`" :title="` Sniper Reloaded`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/22.png`" :name="`Ericsson Balasabas`" :title="` Lil Dicky`"></entourage-item>
                        <div class="break"></div>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/21.png`" :name="`Nikko Prietos`" :title="` Family Guy`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/20.png`" :name="`Jonathan How`" :title="` Mauritian`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/23.png`" :name="`John Ramil Sapa`" :title="` Center Dreamland`"></entourage-item>
                        <div class="break"></div>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/29.png`" :name="`Jed Borromeo`" :title="` Minecraft Boy`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/28.png`" :name="`JJ Mecha`" :title="`Future Pilot`"></entourage-item>
                        <div class="break"></div>
                    </div>
                </div>
                <div class='entourage-panel'>
                    <h2>Bride</h2>
                    <div class="entourage-section">
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/1.png`" :name="`Alyssa Laz`" :title="` Food Artist`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/2.png`"  :name="`Rozelle Beatingo`" :title="` Papyrus User`"></entourage-item>
                    </div>
                    <div class="entourage-section">
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/4.png`" :name="`Stephen Gaitan`" :title="` Seener Child`"></entourage-item>
                    </div>
                    <div class="entourage-section">
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/5.png`" :name="`Necole Dumaicos`" :title="` KPOP Trendsetter`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/6.png`" :name="`Charish Doctora`" :title="` First of her name`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/9.png`" :name="`Genevieve Gentica`" :title="` Luca's Creator`"></entourage-item>
                        <div class="break"></div>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/10.png`" :name="`Colin Marañon`" :title="` Cansi Owner`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/26.png`" :name="`Charmaine Galang`" :title="`Always Available `"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/7.png`" :name="`Daine Rosales `" :title="` Stay at home follower`"></entourage-item>
                        <div class="break"></div>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/27.png`" :name="`Marissa Asenita`" :title="` SG Park Hopper`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/11.png`" :name="`Frauleine Bautista`" :title="` Online Shopper`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/32.png`" :name="`Xiao Wan`" :title="` WhatsApp BFF`"></entourage-item>
                        <div class="break"></div>
                         <entourage-item :image="`http://mowglimecha.com/photodump/entourage/12.png`" :name="`Sophia Sotaridona`" :title="` Mrs Jeon`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/13.png`" :name="`Keziah Alonzo`" :title="` Former Child`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/14.png`" :name="`Mary Joy Sapa`" :title="` Module Passer`"></entourage-item>
                        <div class="break"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="story-board-container">
            <div class="story-panel">
                <div class='entourage-panel-bearers'>
                    <h2>Ring Bearers &amp; Flower Girls</h2>
                    <div class="entourage-section">
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/39.png`" :name="`John Rafa Tulmo`" :title="` Ring Bearer`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/38.png`" :name="`Drake Gavalez`" :title="` Coin Bearer`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/33.png`" :name="`Elio Illustracion`" :title="` Bible Bearer`"></entourage-item>
                         <div class="break"></div>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/34.png`" :name="`Elle Mecha`" :title="` Flower Girl`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/35.png`" :name="`Katarina Patrata`" :title="` Flower Girl`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/36.png`" :name="`Gab Montero`" :title="` Flower Girl`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/37.png`" :name="`Zoie Annika Aguja`" :title="` Flower Girl`"></entourage-item>
                        <entourage-item :image="`http://mowglimecha.com/photodump/entourage/40.png`" :name="`Iya Arenas`" :title="` Flower Girl`"></entourage-item>
                </div>
            </div>
        </div>
    </div>
    </div>

</template>
<style lang="scss" scoped>
@import "./mixins/_mixins.scss";
@import "./vars/colors.scss";
@import "./vars/_breakpoints.scss";
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
    h1 {
        font-family: 'Dancing Script', cursive;
        color:$primary;
        text-align: center;
        margin-top:24px;
        font-size: 2.5rem;
        margin-bottom: 8px;
    }
    .story-board-container {
        padding: 80px 0;
        width:100%;
        height: 100%;
        display: flex;
        align-items:center;
        flex-direction: row;
        box-sizing: border-box;
        justify-content:center;
        background-color: #ffffff;
        background-image: url("https://www.transparenttextures.com/patterns/concrete-wall.png");

        @include sm {
            padding:20px;
        }
    }

    .story-panel {
        border:3px solid #CABB83;
        border-radius: 12px;
        width: 80%;
        margin-bottom: 100px;
        height: auto;
        position: relative;
        display: flex;
        padding: 40px;
        flex-direction: row;

        @include sm {
            padding:20px;
            margin-bottom: 40px;
            flex-direction: column;
        }
    }

    .entourage-panel {
        width: 50%;
        border-collapse: collapse;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        h2 {
            font-family: 'Dancing Script', cursive;
            font-size: 4rem;
            margin:20px 0;
            color:#384A62;
            text-align: center;

            @include sm {
                 font-size: 2rem;
            }
        }


        @include sm {
            width: 100%;
        }
    }
    .entourage-section {
        display: flex;
        flex:1;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 8px;
        width:95%;
        justify-content: flex-start;
    }

    .break {
        flex-basis: 100%;
        display: flex;
        height: 0;

         @include sm {
           display: none;
        }
    }

     .entourage-panel-bearers {
        width: 100%;
        border-collapse: collapse;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        h2 {
            font-family: 'Dancing Script', cursive;
            font-size: 4rem;
            margin:20px 0;
            color:#384A62;
            text-align: center;

            @include sm {
                 font-size: 2rem;
            }
        }


        @include sm {
            width: 100%;
        }
    }

</style>
<script>
import EntourageItem from './EntourageItem.vue';


export default {
    name: 'Entourage',
    components:{
        EntourageItem

    }

}
</script>
